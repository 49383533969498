import experts from "../Assets/main/experts1.svg";
import NavBar from "../Components/NavBar";
import telegramIcon from "../Assets/main/tlg.png";
import icon from "../Assets/logo.svg";
import und from "../Assets/main/image 68 (Traced).svg";
import "../Style/Main.css";
import { Link } from "react-router-dom";

export default function Main({ updateForm }) {
  const submit = () => {
    updateForm();
  };

  return (
    <div className="main-page" id="Main">
      <div className="content">
        <NavBar></NavBar>
        <a href="/"><img src={icon} className="icon" /></a>
        <div className="main-body">
          <div className="m1">Лучшее Юридическое Агентство</div>
          <div className="m2">TVOE PRAVO</div>
          <div className="m3">ЛЕГко решаем сложные вопросы</div>
          <div className="m4">
            Мы тщательно анализируем каждую ситуацию, чтобы предложить наилучшее
            решение. С нами вы можете быть уверены в поддержке на каждом этапе
          </div>
          <div className="flex">
            <button onClick={submit} className="m5">
              записаться на консультацию
            </button>
            <Link to="/services">
              <button className="m5 serv">
                наши услуги
              </button>
            </Link>
          </div>

          <div className="m6">
            Наши специалисты
            <br />
            по Гражданским делам:
          </div>
          <img src={experts} className="experts" />
        </div>
      </div>
    </div>
  );
}
