import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image11.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Corruption({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <div className="left-ff">
            <div className="ff1">Коррупционное право</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юридическая помощь в случаях коррупционных преступлений охватывает
              широкий спектр услуг, начиная от консультаций по предотвращению
              коррупционных рисков до защиты в суде. Это включает подготовку
              защитной стратегии для лиц, обвиняемых в злоупотреблении
              полномочиями, незаконном участии в предпринимательской
              деятельности или в даче и получении взяток. Юристы анализируют
              обстоятельства дела, собирают необходимые доказательства,
              представляют интересы клиентов во время досудебных расследований и
              судебных разбирательств, стремясь обеспечить справедливое и
              объективное рассмотрение дела.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Предварительная консультация и анализ дела: </span>
            Понимание обстоятельств дела, оценка рисков и разработка стратегии
            защиты.
          </div>
          <div className="lst">
            <span>
              2) Представление интересов клиента в следственных органах:{" "}
            </span>
            Содействие во время допросов и других процессуальных действий.
          </div>
          <div className="lst">
            <span>3) Сбор и анализ доказательств: </span>
            Организация необходимых экспертиз и сбор доказательств,
            подтверждающих невиновность клиента или оспаривающих обвинения
          </div>
          <div className="lst">
            <span>4) Подготовка процессуальных документов: </span>Составление и
            подача всех необходимых юридических документов, включая жалобы,
            ходатайства и апелляции.
          </div>
          <div className="lst">
            <span>5) Защита в суде: </span>Представление интересов клиента в
            судебных разбирательствах, включая подготовку и представление
            аргументов защиты.
          </div>
          <div className="lst">
            <span>6) Переговоры о сделке со следствием: </span>Ведение
            переговоров с прокуратурой о возможности сделки по признанию вины в
            обмен на смягчение наказания или другие условия.
          </div>
          <div className="lst">
            <span>7) Консультации по предотвращению коррупции: </span>Помощь
            организациям в разработке и внедрении антикоррупционных политик и
            процедур.
          </div>
          <div className="lst">
            <span>8) Защита от дискриминации и восстановление репутации: </span>
            Поддержка в восстановлении деловой и личной репутации после
            завершения уголовного преследования, включая работу с СМИ и
            общественностью.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
