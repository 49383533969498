import "../Style/AboutCompany.css";
import aboutComp from "../Assets/aboutcompany/about-company.svg";
import { Link } from "react-router-dom";

export default function AboutCompany({ updateForm }) {
  const submit = () => {
    updateForm();
  };

  return (
    <div className="about-company" id="About">
      <div className="content flex">
        <div className="t1 show">О нашей компании</div>
        <div className="about-company-left">
          <div className="t1">О нашей компании</div>
          <div className="t2 p1">
            Наша компания предоставляет юридические услуги для физических и юридических лиц, опираясь на более чем 15 лет опыта. Мы нацелены на высокое качество услуг и достижение наилучшего результата для каждого клиента.
          </div>

          <div className="t2 p2">
            <span>
              Наши принципы работы:
            </span>
          </div>

          <div className="t2 p3">
            1. Ценность клиента — рекомендации клиентов — наша лучшая реклама.
          </div>

          <div className="t2 p3">
            2. Качество услуг — высокий уровень сервиса всегда в приоритете.
          </div>

          <div className="t2 p3">
            3. Честность и конфиденциальность — обещаем только реальные результаты.
          </div>

          <div className="t2 p3">
            4. Профессионализм — команда регулярно повышает квалификацию.
          </div>

          <div className="fle">
            <button onClick={submit} className="t3">
              Оставить Заявку
            </button>
            <Link to='/services'>
              <button className="t3 serv">
                Смотреть все услуги
              </button>
            </Link>
          </div>
        </div>
        <img src={aboutComp} />
      </div>
    </div>
  );
}
