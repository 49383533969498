import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image4.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Family({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <div className="left-ff">
            <div className="ff1">семейные споры</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юрист по семейным спорам специализируется на решении вопросов,
              связанных с семейными отношениями, такими как разводы, раздел
              имущества, алименты и опека над детьми. Он предоставляет
              юридическую помощь в разрешении конфликтов между членами семьи,
              помогает разработать соглашения и представляет интересы клиентов в
              суде. Юрист работает над тем, чтобы обеспечить справедливое и
              законное решение семейных споров, опираясь на знание семейного
              права и судебной практики.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>
              1) Консультация клиента и сбор информации о семейной ситуации.
            </span>
          </div>
          <div className="lst">
            <span>
              2) Анализ юридических аспектов дела и разработка стратегии.
            </span>
          </div>
          <div className="lst">
            <span>3) Подготовка и подача необходимых документов в суд.</span>
          </div>
          <div className="lst">
            <span>
              4) Ведение переговоров и составление соглашений между сторонами.
            </span>
          </div>
          <div className="lst">
            <span>
              5) Представление интересов клиента в судебных заседаниях.
            </span>
          </div>
          <div className="lst">
            <span>6) Мониторинг исполнения судебных решений и соглашений.</span>
          </div>
          <div className="lst">
            <span>
              7) Оказание дополнительной юридической помощи по возникшим
              вопросам после решения спора.
            </span>
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
