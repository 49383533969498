import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image2.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function Land({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <div className="left-ff">
            <div className="ff1">ЮРИСТ ПО ЗЕМЛЕ</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юрист по земельным вопросам ответственен за решение дел, связанных
              с владением, распоряжением и продажей земли. Он оказывает услуги в
              сфере градостроительного и земельного права. Наличие
              профессионального образования и многолетнего опыта работы
              позволяют решать любые проблемы, связанные с земельными участками,
              в том числе земельные споры. Земельные споры относятся к одной из
              самых сложных категорий юриспруденции. Их решение требует
              досконального знания законодательства, а для доказательства
              правоты клиента потребуется проведение технических процедур.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Помощь в определении границ участка (межевание)</span>
          </div>
          <div className="lst">
            <span>
              2) Нарушение интересов собственника земли другими лицами
            </span>
          </div>
          <div className="lst">
            <span>3) Разрешение споров о праве собственности на землю</span>
          </div>
          <div className="lst">
            <span>4) Передача земельного участка в наследство</span>
          </div>
          <div className="lst">
            <span>
              5) Оспаривание решений государственных служб по земельному праву
            </span>
          </div>
          <div className="lst">
            <span>6) Сопровождение сделок с земельными участками</span>
          </div>
          <div className="lst">
            <span>7) Консультация по земельному законодательству страны</span>
          </div>
          <div className="lst">
            <span>8) Представительство в судебных инстанциях</span>
          </div>
          <div className="lst">
            <span>
              9) Оформление договоров о купле-продаже, дарении, передаче в
              наследство земельных участков
            </span>
          </div>
          <div className="lst">
            <span>
              10) Оспаривание нарушения земельного сервитута (ограниченного
              права владения чужим участком)
            </span>
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
