import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image1.png";
import logo from "../../Assets/logo.svg";
import { useNavigate } from "react-router-dom";

export default function PropertyCrime({ updateForm }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="page sobst">
        <div className="content">
          <a href="/"><img src={logo} className="logo" /></a>
          <div className="left-ff">
            <div className="ff1">Преступления против собственности</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юридическая помощь по делам, связанным с преступлениями против
              собственности, охватывает комплекс услуг, начиная от консультаций
              и до защиты в суде. Юристы предоставляют поддержку в делах о
              краже, мошенничестве, грабеже, разбое и других преступлениях,
              направленных против чужой собственности. Это включает анализ
              обстоятельств дела, сбор доказательств, разработку защитной
              стратегии, представление интересов клиента в судах и полиции, а
              также помощь в восстановлении прав и компенсации ущерба. Подход к
              каждому делу индивидуален, учитывая специфику обвинений и
              обстоятельства преступления.
            </div>
            <div className="buttons">
              <button onClick={() => navigate(-1)} className="ff4">
                Назад
              </button>
              <button onClick={updateForm} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации:</span> Предоставление профессиональных
            юридических консультаций, разъяснение прав и обязанностей клиента,
            оценка перспектив дела и рекомендации по дальнейшим действиям.
          </div>
          <div className="lst">
            <span>2) Анализ дела:</span> Детальное изучение обстоятельств дела,
            проверка материалов, представленных следствием, и выявление
            возможных ошибок или нарушений..
          </div>
          <div className="lst">
            <span>3) Сбор доказательств:</span> Организация и проведение
            независимых экспертиз, сбор дополнительных доказательств и поиск
            свидетелей, которые могут поддержать защиту клиента.
          </div>
          <div className="lst">
            <span>4) Разработка стратегии защиты:</span> Создание индивидуальной
            стратегии защиты, учитывающей все нюансы дела, и определение
            оптимальных способов защиты интересов клиента.
          </div>
          <div className="lst">
            <span>5) Представление в суде:</span> Юридическое представительство
            клиента на всех стадиях судебного разбирательства, включая
            подготовку и подачу ходатайств и апелляций.
          </div>
          <div className="lst">
            <span>6) Переговоры о досудебном урегулировании:</span> Ведение
            переговоров с противоположной стороной для достижения досудебного
            урегулирования и минимизации негативных последствий для клиента
          </div>
          <div className="lst">
            <span>7) Обжалование судебных решений:</span> Подготовка и подача
            апелляционных и кассационных жалоб в случае несогласия с вынесенным
            решением суда.
          </div>
          <div className="lst">
            <span>8) Восстановление прав и компенсация ущерба:</span> Помощь в
            восстановлении нарушенных прав клиента, в том числе через получение
            компенсации за нанесенный материальный или моральный ущерб.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
